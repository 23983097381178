.main-content,
.main-title {
  margin-left: 20px;
}

.weather-gauge {
  height: 100px;
  width: 100%;
  background: rgb(255, 0, 0);
  background: linear-gradient(to top, red 0px, yellow 50px, green 100px);
  position: absolute;
  bottom: 0;
}

.weather-gauge-wrapper {
  border: 1px solid black;
  width: 20px;
  height: 100px;
  position: relative;
  box-sizing: border-box;
  margin-left: 20px;
}

.weather-gauge-wrapper:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
